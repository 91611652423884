import { useField } from 'remix-validated-form'
import { FormInputWrapperProps } from 'app/components/form/FormInputWrapper'

type FormInputProps = {
  name: string
  value?: string | string[] | undefined
} & FormInputWrapperProps<HTMLInputElement>

export default function FormHiddenInput({ name, value, ...props }: FormInputProps) {
  const { getInputProps } = useField(name)

  return (
    <>
      <input {...getInputProps({ ...props, value, type: `hidden` })} />
    </>
  )
}
