import classNames from 'classnames'
import { WarningCircle } from 'iconoir-react'
import { InputHTMLAttributes } from 'react'
import { Lock } from '../contacts/Lock'
import { Info } from '../Info'

export type FormInputWrapperProps<T> = {
  name: string
  label?: string
  placeholder?: string
  error?: string
  className?: string
  wrapperClassName?: string
  errorClassName?: string
  cols?: 1 | 2 | 3 | 4 | 5 | 6
  labelChildren?: React.ReactElement
  showErrorIcon?: boolean
  newLineError?: boolean
  infoTooltip?: { title: string; message: string }
  isLocked?: boolean
} & InputHTMLAttributes<T>

export const BaseInputClasses = classNames({
  'block w-full bg-white rounded-lg border-trn-200 py-[14px] px-[17px] text-trn-900 shadow-elevation-1 ring-offset-0 focus:border-trn-200 focus:text-body-sm focus:text-trn-900 focus:outline-none sm:text-body-sm disabled:cursor-not-allowed':
    true,
})

export default function FormInputWrapper({
  label,
  error,
  name,
  children,
  wrapperClassName,
  className = `text-body-sm`,
  errorClassName,
  labelChildren,
  showErrorIcon = false,
  newLineError = true,
  cols = 1,
  infoTooltip,
  isLocked = false,
}: FormInputWrapperProps<any>) {
  return (
    <div className={classNames(`col-span-${cols}`)}>
      <div className={wrapperClassName}>
        {label && (
          <label htmlFor={name} className={classNames(className, error && `text-red-600`)}>
            <div className="inline-flex items-center gap-x-2">
              <span>{label}</span>
              {!!infoTooltip && <Info title={infoTooltip.title} message={infoTooltip.message} />}
              {isLocked && <Lock />}
            </div>
            {labelChildren}
          </label>
        )}
        {children}
      </div>
      {error && (
        <>
          {newLineError ? (
            <div className={classNames(errorClassName, `mt-[9px] text-body-sm text-red-600`)}>{error}</div>
          ) : (
            <div
              className={classNames(
                errorClassName,
                `mt-[9px] flex items-center justify-end gap-x-2 text-body-sm text-red-600`,
              )}
            >
              {showErrorIcon && <WarningCircle className="h-full w-6 text-red-600" />}
              {error}
            </div>
          )}
        </>
      )}
    </div>
  )
}
